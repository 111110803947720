import React, { useState } from 'react';

function App() {
  const [cisloPojistence, setCisloPojistence] = useState('');
  const [datum, setDatum] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);  // resetování chyby před novým pokusem
    setResponseData('Načítám...');
  
    try {
      const response = await fetch('https://vzptest.dc-flipper.cz:5000/api/vzp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cisloPojistence, datum }),
      });
  
      if (!response.ok) {
        throw new Error(`Chyba při komunikaci se serverem: ${response.statusText}`);
      }
  
      const responseJson = await response.json();
      console.log("Odpověď z backendu:", responseJson);
  
      // Pokud je stav "nepojištěn", zobrazí se tato hláška
      if (responseJson.stav === 'Osoba není pojištěna') {
        setResponseData('<strong>Výsledek:</strong> Osoba není pojištěna');
      } else {
        // Zobrazíme všechny informace, pokud je osoba pojištěná
        const formattedResult = `
          <strong>Stav:</strong> ${responseJson.stav || 'Není k dispozici'}<br>
          <strong>Číslo pojišťovny:</strong> ${responseJson.kodPojistovny || 'Není k dispozici'}<br>
          <strong>Pojišťovna:</strong> ${responseJson.nazevPojistovny || 'Není k dispozici'}<br>
          <strong>Stav vyřízení požadavku:</strong> ${responseJson.stavVyrizeniPozadavku || 'Není k dispozici'}<br>
        `;
        setResponseData(formattedResult);
      }
    } catch (error) {
      setError(error.message);
      setResponseData(`<strong>Chyba:</strong> ${error.message}`);
    }
  };
  
  
  return (
    <div className="container">
      <h1>VZP Formulář</h1>
      <form id="vzp-form" onSubmit={handleSubmit}>
        <label htmlFor="cisloPojistence">Číslo pojištěnce:</label>
        <input
          type="text"
          id="cisloPojistence"
          value={cisloPojistence}
          onChange={(e) => setCisloPojistence(e.target.value)}
          required
        />
        <label htmlFor="datum">Datum:</label>
        <input
          type="date"
          id="datum"
          value={datum}
          onChange={(e) => setDatum(e.target.value)}
          required
        />
        <button type="submit">Odeslat</button>
      </form>

      <div id="vysledek" className="vysledek" style={{ display: responseData ? 'block' : 'none' }}>
        <h2>Výsledek:</h2>
        <pre id="response-data" dangerouslySetInnerHTML={{ __html: responseData }} />
      </div>
    </div>
  );
}

export default App;
