// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Základní styl pro celou stránku */
*{
  font-family: "Poppins", sans-serif;

}
body {
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #dd9933;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-size: 14px;
}

input[type="text"],
input[type="date"] {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

button {
  padding: 10px;
  background-color: #7A7A7A;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #54595F;
}

.vysledek {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;EACE,kCAAkC;;AAEpC;AACA;EACE,yBAAyB;EACzB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;;EAEE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,0CAA0C;AAC5C;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,WAAW;AACb","sourcesContent":["/* Základní styl pro celou stránku */\n*{\n  font-family: \"Poppins\", sans-serif;\n\n}\nbody {\n  background-color: #f4f4f9;\n  margin: 0;\n  padding: 0;\n}\n\n.container {\n  max-width: 800px;\n  margin: 20px auto;\n  padding: 20px;\n  background-color: #dd9933;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\nh1 {\n  text-align: center;\n  color: #333;\n}\n\nform {\n  display: flex;\n  flex-direction: column;\n}\n\nlabel {\n  margin-bottom: 5px;\n  font-size: 14px;\n}\n\ninput[type=\"text\"],\ninput[type=\"date\"] {\n  padding: 10px;\n  margin-bottom: 15px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 14px;\n}\n\nbutton {\n  padding: 10px;\n  background-color: #7A7A7A;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  font-size: 16px;\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #54595F;\n}\n\n.vysledek {\n  margin-top: 20px;\n  padding: 20px;\n  background-color: #ffffff;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);\n}\n\npre {\n  white-space: pre-wrap;\n  word-wrap: break-word;\n  font-size: 14px;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
